import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import 'slick-carousel';

/*==============================
=          GLOBAL  JS          =
==============================*/
$(function () {
	// Set CSS variable for the scrollbar width
	document.documentElement.style.setProperty(
		'--scrollbar-width',
		window.innerWidth - document.documentElement.clientWidth + 'px'
	);

	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;

	$(window).on('scroll', function () {
		didScroll = true;

		if ($(window).scrollTop() > 0) {
			$('body').addClass('scrolled');
		} else {
			$('body').removeClass('scrolled');
		}
	});

	setInterval(function () {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var scrollTop = $(window).scrollTop();
		var navbarHeight = $('header').outerHeight();

		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - scrollTop) <= delta) {
			return;
		}

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
			// Scroll Down
			$('header').css('top', '-' + navbarHeight + 'px');
			$('body').addClass('header-hidden');
		} else {
			// Scroll Up
			if (scrollTop + $(window).height() < $(document).height()) {
				$('header').css('top', '0px');
				$('body').removeClass('header-hidden');
			}
		}

		lastScrollTop = scrollTop;
	}

	$('.desktop-nav-trigger').on('click', function (event) {
		if ($('body').hasClass('full-nav-open')) {
			closeFullNav();
		} else {
			$('body').addClass('full-nav-open');

			// Open the full nav in case it was closed
			$('.full-nav-container').addClass('full-nav-open');
		}

		event.preventDefault();
	});

	$('header a.icon-search').on('click', function (event) {
		if ($(window).width() > 700) {
			$('body').addClass('nav-search-open');
			$('.nav-search-form input').focus();
		} else {
			window.location.href = '/search';
		}

		event.preventDefault();
	});

	$(document).bind('click', function (event) {
		var clicked = $(event.target);

		// Clicking off the full nav closes it
		if (!clicked.parents().is('header')) {
			closeFullNav();
		}

		// Clicking off dropdown closes it
		if (!clicked.parents().hasClass('dropdown')) {
			closeDropdown();
		}

		// Clicking off the nav search closes it
		// As long as the clicked item isn't a link or part of the search form
		if (
			$('body').hasClass('nav-search-open') &&
			!clicked.parents().is('.nav-search-form') &&
			!clicked.is('a') &&
			!clicked.parents().is('a')
		) {
			$('body').removeClass('nav-search-open');
		}
	});

	// esc key closes full nav and nav search
	$(document).on('keyup', function (event) {
		if (event.keyCode === 27) {
			// ESC
			closeFullNav();
			$('body').removeClass('nav-search-open');
		}
	});

	// Close and reset the full nav
	function closeFullNav() {
		$('body').removeClass('full-nav-open');
	}

	$('.mobile-nav-trigger').on('click', function (event) {
		$('body').toggleClass('mobile-nav-open');

		event.preventDefault();
	});

	// Mobile nav
	$('.mobile-nav .level-1 > a').on('click', function (event) {
		if ($(this).closest('li').hasClass('open')) {
			$(this).closest('li').find('ul').stop().slideUp(250);
			$(this).closest('li').removeClass('open');
		} else {
			$('.mobile-nav .level-1 ul').stop().slideUp(250);
			$(this).closest('li').find('ul').stop().slideDown(250);

			$('.mobile-nav .level-1').removeClass('open');
			$(this).closest('li').addClass('open');
		}

		event.preventDefault();
	});

	// Dropdown
	$('.dropdown__active').click(function (event) {
		if ($(this).closest('.dropdown').hasClass('open')) {
			$('.dropdown').removeClass('open');
			$('body').removeClass('dropdown-open');
		} else {
			$('.dropdown').removeClass('open');
			$(this).closest('.dropdown').addClass('open');
			$('body').addClass('dropdown-open');
		}

		event.preventDefault();
	});

	$('.close-dropdown-popup').click(function (event) {
		closeDropdown();

		event.preventDefault();
	});

	function closeDropdown() {
		$('.dropdown__active').closest('.dropdown').removeClass('open');
		$('body').removeClass('dropdown-open');
	}

	// Entry header accordion
	$('.entry-header__info-toggle').click(function (event) {
		if ($('.entry-header').hasClass('info-open')) {
			$('.entry-header').removeClass('info-open');
			$('.entry-header__info').stop().slideUp(250);
		} else {
			$('.entry-header').addClass('info-open');
			$('.entry-header__info').stop().slideDown(250);
		}

		event.preventDefault();
	});

	// Scroll Reveal
	var revealSlideLeft = {
		distance: '20px',
		origin: 'right',
		opacity: 0,
		delay: 500,
		duration: 1000,
		easing: 'cubic-bezier(0.5, 0, 0, 1)',
		reset: false,
	};
	ScrollReveal().reveal('.reveal--slide-left', revealSlideLeft);

	var revealSlideRight = {
		distance: '20px',
		origin: 'left',
		opacity: 0,
		delay: 500,
		duration: 1000,
		easing: 'cubic-bezier(0.5, 0, 0, 1)',
		reset: false,
	};
	ScrollReveal().reveal('.reveal--slide-right', revealSlideRight);

	var revealSlideUp = {
		distance: '30px',
		origin: 'bottom',
		opacity: 0,
		delay: 500,
		duration: 1000,
		easing: 'cubic-bezier(0.5, 0, 0, 1)',
		reset: false,
	};
	ScrollReveal().reveal('.reveal--slide-up', revealSlideUp);

	var revealFadeIn = {
		opacity: 0,
		delay: 400,
		duration: 1000,
		easing: 'cubic-bezier(0.5, 0, 0, 1)',
		reset: false,
	};
	ScrollReveal().reveal('.reveal--fade-in', revealFadeIn);

	var revealFadeInStagger = {
		opacity: 0,
		delay: 400,
		duration: 1000,
		interval: 250,
		easing: 'cubic-bezier(0.5, 0, 0, 1)',
		reset: false,
	};
	ScrollReveal().reveal('.reveal--fade-in-stagger', revealFadeInStagger);

	var revealSlideUpStagger = {
		distance: '20px',
		origin: 'bottom',
		opacity: 0,
		delay: 500,
		duration: 1000,
		interval: 250,
		easing: 'cubic-bezier(0.5, 0, 0, 1)',
		reset: false,
	};
	ScrollReveal().reveal('.reveal--slide-up-stagger', revealSlideUpStagger);

	var revealQuotes = {
		distance: '20px',
		origin: 'left',
		opacity: 0,
		delay: 400,
		duration: 400,
		easing: 'cubic-bezier(0.3, 0, 0.3, 1)',
		reset: false,
	};
	ScrollReveal().reveal('.reveal--quotes', revealQuotes);

	// Side-by-Side Images slider for mobile
	var slickSideBySideImages = $('.side-by-side-images');
	var iconGrid = $('.slick-icon-grid');

	var slickArrowLeft =
		'<span class="slick-arrow slick-arrow--left"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23"><polyline fill="none" stroke="#0FB9A3" points="17.059 6 6.03 17.029 -5 6" transform="rotate(90 6.03 11.514)"/></svg></span>';
	var slickArrowRight =
		'<span class="slick-arrow slick-arrow--right"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23"><polyline fill="none" stroke="#0FB9A3" points="17.059 6 6.03 17.029 -5 6" transform="rotate(-90 6.03 11.514)"/></svg></span>';

	function handleSlick() {
		// Side-by-Side Images
		if ($(window).width() > 925) {
			if (slickSideBySideImages.hasClass('slick-initialized')) {
				slickSideBySideImages.slick('unslick');
			}
		} else if (!slickSideBySideImages.hasClass('slick-initialized')) {
			slickSideBySideImages.slick({
				infinite: false,
				prevArrow: slickArrowLeft,
				nextArrow: slickArrowRight,
			});
		}

		// Icon Grid
		if ($(window).width() > 600) {
			if (iconGrid.hasClass('slick-initialized')) {
				iconGrid.slick('unslick');
			}
		} else if (!iconGrid.hasClass('slick-initialized')) {
			ScrollReveal().clean('.slick-icon-grid .reveal--slide-up');

			iconGrid.slick({
				infinite: false,
				prevArrow: slickArrowLeft,
				nextArrow: slickArrowRight,
			});
		}
	}

	handleSlick();

	// Side-by-Side Images slider for mobile
	var slickImageCarouselArrowLeft =
		'<span class="slick-arrow slick-arrow--left"><svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" transform="translate(0 0.700195)" fill="#0CA19C"/><path d="M14.3047 28.3335L21.938 20.7002L14.3047 13.0502L16.6547 10.7002L26.6547 20.7002L16.6547 30.7002L14.3047 28.3335Z" fill="#FEFEFE"/></svg></span>';
	var slickImageCarouselArrowRight =
		'<span class="slick-arrow slick-arrow--right"><svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" transform="translate(0 0.700195)" fill="#0CA19C"/><path d="M14.3047 28.3335L21.938 20.7002L14.3047 13.0502L16.6547 10.7002L26.6547 20.7002L16.6547 30.7002L14.3047 28.3335Z" fill="#FEFEFE"/></svg></span>';

	var slickImageCarousel = $('.slick-image-carousel');
	slickImageCarousel.slick({
		infinite: false,
		prevArrow: slickImageCarouselArrowLeft,
		nextArrow: slickImageCarouselArrowRight,
		dots: true,
	});

	// Trigger click on space or enter keyup on role="button" elements
	$('[role="button"]').keyup(function (event) {
		if (event.which === 32 || event.which === 13) {
			$(this).trigger('click');
			event.preventDefault();
		}
	});

	// Stop browser window from scrolling when pressing space key while focused on a [role="button"] element
	$('[role="button"]').keypress(function (event) {
		if (event.which === 32) {
			event.preventDefault();
		}
	});

	if (document.cookie.indexOf('cookie_banner=') < 0) {
		var now = new Date();
		var time = now.getTime();
		time += 400 * 24 * 60 * 60 * 1000;
		now.setTime(time);
		document.cookie =
			'cookie_banner=1' + '; expires=' + now.toGMTString() + '; path=/';

		$('.cookie-banner').show();
	}

	$('.cookie-banner__close').on('click', function (event) {
		$('.cookie-banner').hide();

		event.preventDefault();
	});

	/*=========================================
	=                 ON LOAD                 =
	=========================================*/
	$(window).on('load', function () {});

	/*=========================================
	=                ON RESIZE                =
	=========================================*/
	$(window).on('resize', function () {
		handleSlick();

		// Close all overlays
		closeFullNav();
		closeDropdown();
		$('body').removeClass('mobile-nav-open');
	});
});
